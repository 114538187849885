import { AppThunk } from "..";
import { authClient } from "../../lib/api";
import { apis } from "../../lib/gateways";
import { uiActions } from "../states";
import { lists } from "./state";
import { AppState } from "../../state";

const { actions } = lists;

// Term (https://api-development.loantracker.net/lists/maxTerm)
// States (https://api-development.loantracker.net/states)

export const listActions = {
  ...actions,
  getQuoteDealLists(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      dispatch(listActions.getTermList());
      dispatch(listActions.getRecourseList());
      dispatch(listActions.getLendingProgramsList());
      dispatch(listActions.getInterestOnlyList());
      dispatch(listActions.getPrepaymentPenaltyList());
      dispatch(listActions.getAmortizationList());
      dispatch(listActions.getExecutionTypeList());
      dispatch(listActions.getMinDscrList());
      dispatch(listActions.getIndexTypes());
    };
  },
  getFilterLists(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      dispatch(listActions.getTermList());
      dispatch(listActions.getPropertyTypeList());
      dispatch(listActions.getLoanTypeList());
      dispatch(listActions.getStates());
    };
  },
  getLendingProfileLists(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      dispatch(listActions.getTermList());
      dispatch(listActions.getExecutionTypeList());
      dispatch(listActions.getExecutionTypeMapping());
      dispatch(listActions.getAmortizationList());
      dispatch(listActions.getPrepaymentPenaltyList());
      dispatch(listActions.getPropertyTypeList());
      dispatch(listActions.getRecourseList());
      dispatch(listActions.getLoanTypeList());
      dispatch(listActions.getMinDscrList());
    };
  },
  getLendingProgramsList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { lendingPrograms } = getState().lists;
      if (lendingPrograms.length) return;
      const { data } = await authClient.get(apis.LendingTypesList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get lending programs"));
      } else {
        dispatch(actions.setLendingProgramsList(data.data));
      }
    };
  },
  getDealLoanTypeList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { dealLoanTypeList } = getState().lists;
      if (dealLoanTypeList.length) return;
      const { data } = await authClient.get(apis.DealLoanTypesList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get deal loan type programs"));
      } else {
        dispatch(actions.setDealLoanTypeList(data.data));
      }
    };
  },
  getLoanTypeList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { loanTypeList } = getState().lists;
      if (loanTypeList.length) return;
      const { data } = await authClient.get(apis.LoanTypeList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get loan type programs"));
      } else {
        dispatch(actions.setLoanTypeList(data.data));
      }
    };
  },
  getDesignatedOriginatorList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { userCompanyId } = await getState().auth;
      if (!userCompanyId) {
        return;
      }
      const { data } = await authClient.get(
        apis.BanksBankersList(userCompanyId),
      );
      if (data.isResponse) {
        dispatch(actions.setDesignatedOriginatorList(data.data));
      } else {
        dispatch(
          uiActions.showError("Failed to get designated originator list"),
        );
      }
    };
  },
  getExecutionTypeMapping(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { executionTypeMapping } = getState().lists;
      if (Object.keys(executionTypeMapping).length !== 0) return;
      const { data } = await authClient.get(apis.ExecutionTypeMapping);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get execution type mapping"));
      } else {
        dispatch(actions.setExecutionTypeMappingList(data.data));
      }
    };
  },
  getTermList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { termList } = getState().lists;
      if (termList.length) return;
      const { data } = await authClient.get(apis.MaxTermList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get terms"));
      } else {
        dispatch(actions.setTermList(data.data));
      }
    };
  },
  getRecourseList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { recourseList } = getState().lists;
      if (recourseList.length) return;
      const { data } = await authClient.get(apis.RecourseList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get recourse list"));
      } else {
        dispatch(actions.setRecourseList(data.data));
      }
    };
  },
  getMinDscrList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { minDscrList } = getState().lists;
      if (minDscrList.length) return;
      const { data } = await authClient.get(apis.MinDscrList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get min dscr list"));
      } else {
        dispatch(actions.setMinDscrList(data.data));
      }
    };
  },

  getInterestOnlyList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { interestOnlyList } = getState().lists;
      if (interestOnlyList.length) return;
      const { data } = await authClient.get(apis.MaxInterestOnlyList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get lending programs"));
      } else {
        dispatch(actions.setInterestOnlyList(data.data));
      }
    };
  },
  getExecutionTypeList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { executionList } = getState().lists;
      if (executionList.length) return;
      const { data } = await authClient.get(apis.ExecutionTypeList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get lending programs"));
      } else {
        dispatch(actions.setExecutionTypeList(data.data));
      }
    };
  },
  getPrepaymentPenaltyList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { prePaymentPenaltyList } = getState().lists;
      if (prePaymentPenaltyList.length) return;
      const { data } = await authClient.get(apis.PrepayTypeList);
      if (!data.isResponse) {
        dispatch(
          uiActions.showError("failed to get pre payment penalty list values"),
        );
      } else {
        dispatch(actions.setPrePaymentPenaltyList(data.data));
      }
    };
  },
  getAmortizationList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { amortizationList } = getState().lists;
      if (amortizationList.length) return;
      const { data } = await authClient.get(apis.MaxAmortizationList);
      if (!data.isResponse) {
        dispatch(
          uiActions.showError("failed to get pre amortization list values"),
        );
      } else {
        dispatch(
          actions.setAmortizationList(
            data.data.concat({
              id: 0,
              value: "N/A",
              description: "max_amortization",
            }),
          ),
        );
      }
    };
  },
  getStates(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { states } = getState().lists;
      if (states.length) return;
      const { data } = await authClient.get(apis.StateList);
      if (!data.isResponse) {
        dispatch(uiActions.showError("failed to get list of states "));
      } else {
        dispatch(actions.setStates(data.data));
      }
    };
  },
  getPropertyTypeList(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { propertyTypeList } = getState().lists;
      if (propertyTypeList.length) return;
      const { data } = await authClient.get(apis.PropertyTypeList);
      if (!data.isResponse) {
        dispatch(
          uiActions.showError("failed to get property type list values"),
        );
      } else {
        dispatch(actions.setPropertyTypeList(data.data));
      }
    };
  },
  getIndexTypes(): AppThunk {
    return async (dispatch, getState: () => AppState) => {
      const { data } = await authClient.get(apis.SimpleRateSheet);
      if (data.isResponse) {
        dispatch(actions.setIndexType(data.data));
      } else {
        dispatch(uiActions.showError("Failed to get index types"));
      }
    };
  },
};
